import { FieldElement } from '@modular-forms/solid'
import { Component, For, createEffect, createSignal } from 'solid-js'
import { ImagesUploadFieldProps } from '~/components/images-upload-field/images-upload-field.interfaces'
import styles from '~/components/images-upload-field/images-upload-field.module.scss'
import UploadIcon from '~/assets/icons/cloud-upload.svg'
import { reorderPagesByFilename } from '~/services/import/images'

const ImagesUploadField: Component<ImagesUploadFieldProps> = ({ label, error, value, onChange, existingURLs = [], ...props }) => {
  const classList = () => ({
    [`${styles.container}`]: true,
    [`${styles.hasError}`]: Boolean(error),
  })
  const [pagesURLs, setPagesURLs] = createSignal<string[]>()

  createEffect(() => {
    setPagesURLs(existingURLs)
  })

  const onFilesChange = (event: Event & { currentTarget: FieldElement; target: Element; }) => {
    const files = value
    if (files) {
      const urls: string[] = []
      const reorderedFiles = reorderPagesByFilename(files)
      for (const file of reorderedFiles) {
        urls.push(URL.createObjectURL(file))
      }
      setPagesURLs(urls)
    }
    onChange(event)
  }

  return (
    <div classList={classList()}>
      {label && (
        <div class={styles.label}>
          {label}
        </div>
      )}

      <div class={styles.fileUploadButton}>
        <UploadIcon class={styles.uploadIcon} />
        <input
          class={styles.fileUploadInput}
          type='file'
          accept='image/*'
          multiple
          {...props}
          onChange={onFilesChange}
        />
      </div>

      {pagesURLs()?.length && (
        <div class={styles.fileUploadThumbnails}>
          <For each={pagesURLs()}>
            {(url) => <img src={url} class={styles.fileUploadThumbnail} />}
          </For>
        </div>
      )}

      {error && (
        <div class={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
}

export default ImagesUploadField
